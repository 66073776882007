import { Box, Divider, Stack, Typography } from '@mui/material';
import { useLocalStorage } from '@uidotdev/usehooks';
import useProjectSummaryReports from 'api-new/stim-based/useProjectSummaryReports';
import ProjectSummaryReport from 'pages/ProjectOverview/ReportsTab/AiReports/ProjectSummaryReport/ProjectSummaryReport';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import AiSummaryReportStatus from 'enums/AiSummaryReportStatus';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import SessionSummaryReportsList from './SessionSummaryReportsList/SessionSummaryReportsList';
import NoReportsPlaceholder from './NoReportsPlaceholder';
import { getProjectSummaryReportStatus, hasUnfinishedSessionSummaryReports } from './util';
import useProjectFocusGroups from 'api-new/stim-based/useProjectFocusGroups';
import useProjectSessionSummaryReportsOverview from 'api-new/stim-based/useProjectSessionSummaryReportsOverview';
import { useTranslation } from 'react-i18next';
import ReportsTabBreadcrumbs from '../ReportsTabBreadcrumbs';

const AiReportsTab = () => {
    const { t } = useTranslation();
    const { projectId } = useOutletContext();
    const [pollRate, setPollRate] = useState(false);
    const { data: focusGroups } = useProjectFocusGroups(projectId, {
        refetchInterval: 5000,
        select: (data) => data.filter(x => x.isStimulusBasedSession)
    });
    const { data: summaryReports } = useProjectSessionSummaryReportsOverview(projectId, 5000);
    const { data: projectReport, isLoading, refetch: refetchProjectSummaryReports } = useProjectSummaryReports(projectId, {
        refetchInterval: pollRate,
        select: (data) => data?.[0]
    });
    const projectReportId = projectReport?.id;
    const [reportRequestedDate, setReportRequestedDate] = useLocalStorage(`report-requested-date-${projectId}`, null);
    const reportStatus = getProjectSummaryReportStatus(projectReport, reportRequestedDate);
    const hasUnfinishedReports = hasUnfinishedSessionSummaryReports(focusGroups, summaryReports);

    const projectReportGenerationDisabledReason = summaryReports?.length < 2
        ? t('aiReportsTab.generateProjectSummary.atLeastTwoSessionSummariesRequired')
        : '';

    useEffect(() => {
        if (reportStatus === AiSummaryReportStatus.PROCESSING) {
            setPollRate(3000);
        } else {
            setPollRate(false);
        }

        return () => {
            setPollRate(false);
        };
    }, [reportStatus]);

    if (isLoading) {
        return <LoadingPlaceholder height={200} />;
    }

    const placeholder =
        <Stack>
            <Typography variant="h5" gutterBottom>
                { t('project.aiReports.generateProjectSummary') }
            </Typography>
            <NoReportsPlaceholder
                projectId={projectId}
                onGenerateReportRequestSuccess={() => setReportRequestedDate(DateTime.now().toUTC().toISO())}
                aiSummaryReportId={projectReportId}
                projectReportStatus={reportStatus}
                warnBeforeGenerating={hasUnfinishedReports}
                reportGenerationDisabledReason={projectReportGenerationDisabledReason}
            />
        </Stack>;

    const reportContent =
        <ProjectSummaryReport
            projectId={projectId}
            projectReportId={projectReportId}
            reportStatus={reportStatus}
            onReportRegenerated={() => setReportRequestedDate(DateTime.now().toUTC().toISO())}
            onReportDeleted={() => {
                setReportRequestedDate(null);
                refetchProjectSummaryReports();
            }}
        />;

    return (
        <Stack useFlexGap gap={4} sx={{ padding: 4 }}>
            <ReportsTabBreadcrumbs>
                <Typography sx={{ color: 'text.primary' }}>
                    { t('reportsHomepage.aiSummaryReports.title') }
                </Typography>
            </ReportsTabBreadcrumbs>

            { reportStatus !== AiSummaryReportStatus.SUCCESS
                ? placeholder
                : reportContent
            }

            <Divider variant='middle' />

            <Box>
                <Typography variant="h5" gutterBottom>
                    { t('project.aiReports.sessionSummaryReports') }
                </Typography>
                <SessionSummaryReportsList
                    projectId={projectId}
                    projectReportId={projectReportId}
                    focusGroups={focusGroups}
                    summaryReports={summaryReports}
                />
            </Box>
        </Stack>
    );
};

export default AiReportsTab;
