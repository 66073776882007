import PropTypes from 'prop-types';
import { Chip, Link, ListItem, ListItemAvatar, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import SummaryReportStatusAvatar from './SummaryReportStatusAvatar';
import SummaryReportStatusText from './SummaryReportStatusText/SummaryReportStatusText';
import { FocusGroupSummaryReportStatus, getFocusGroupSummaryReportStatus } from '../util';
import { useOutletContext } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import SummaryReportListItemAction from './SummaryReportListItemAction/SummaryReportListItemAction';
import { useLocalStorage } from '@uidotdev/usehooks';
import { DateTime, Interval } from 'luxon';
import { InfoOutlined } from '@mui/icons-material';
import { REPORT_PROCESSING_WINDOW_MINUTES } from 'Config';

/**
 * Determine if the report should be forced into a 'processing' state
 *
 * @param {*} reportRequestedDate The date at which the report was last requested (generated)
 * @param {*} summaryReportModifiedDate The date at which the report was last modified on the backend
 * @returns true if the report should be regarded as 'processing' - false otherwise.
 */
const shouldForceProcessingState = (reportRequestedDate, summaryReportModifiedDate) => {
    const requestDateTime = DateTime.fromISO(reportRequestedDate).toUTC();

    if (!summaryReportModifiedDate) {
        const minutesSinceReportRequested = Interval.fromDateTimes(requestDateTime, DateTime.now()).toDuration('minutes').minutes;
        const isNewRequest = minutesSinceReportRequested < REPORT_PROCESSING_WINDOW_MINUTES;

        return isNewRequest;
    }

    const modifiedDateTime = DateTime.fromISO(summaryReportModifiedDate).toUTC();
    return requestDateTime > modifiedDateTime;
};

const SessionSummaryReportsListItem = ({
    focusGroupId,
    focusGroupTitle,
    focusGroupStatus,
    focusGroupScheduledStartTime,
    focusGroupActualEndTime,
    summaryReportId,
    summaryReportStatus,
    summaryReportModifiedDate,
    warnGeneratedAfterReport
}) => {
    const { companyId, projectId } = useOutletContext();
    const [reportRequestedDate, setReportRequestedDate] = useLocalStorage(`report-requested-date-${focusGroupId}`, focusGroupActualEndTime);

    const focusGroupSummaryReportStatus = shouldForceProcessingState(reportRequestedDate, summaryReportModifiedDate)
        ? FocusGroupSummaryReportStatus.PROCESSING_SUMMARY_REPORT
        : getFocusGroupSummaryReportStatus(focusGroupStatus, summaryReportStatus);

    return (
        <ListItem
            sx={{ backgroundColor: 'background.paper' }}
            secondaryAction={
                <SummaryReportListItemAction
                    focusGroupId={focusGroupId}
                    summaryReportId={summaryReportId}
                    focusGroupSummaryReportStatus={focusGroupSummaryReportStatus}
                    onReportRequested={() => setReportRequestedDate(DateTime.now().toUTC().toISO())}
                />
            }
        >
            <ListItemAvatar>
                <SummaryReportStatusAvatar focusGroupSummaryReportStatus={focusGroupSummaryReportStatus} />
            </ListItemAvatar>

            <ListItemText
                disableTypography
                primary={
                    <Link
                        to={`/company/${companyId}/project/${projectId}/activity/${focusGroupId}`}
                        component={RouterLink}
                        underline="hover"
                        target='_blank'
                    >
                        <Typography variant="body1" color="textPrimary">
                            { focusGroupTitle }
                        </Typography>
                    </Link>
                }
                secondary={
                    <Stack direction={'row'} gap={1} alignItems={'middle'}>
                        <SummaryReportStatusText
                            focusGroupId={focusGroupId}
                            focusGroupScheduledStartTime={focusGroupScheduledStartTime}
                            summaryReportId={summaryReportId}
                            focusGroupSummaryReportStatus={focusGroupSummaryReportStatus}
                        />

                        { warnGeneratedAfterReport &&
                            <Tooltip arrow title="This report was generated after the Project Report and so was not included in analysis">
                                <Chip sx={{ cursor: 'help' }} color="warning" icon={<InfoOutlined />} label="Not in project report" size='small' />
                            </Tooltip>
                        }
                    </Stack>
                }
            />
        </ListItem>
    );
};

SessionSummaryReportsListItem.propTypes = {
    focusGroupId: PropTypes.string,
    focusGroupStatus: PropTypes.string,
    focusGroupTitle: PropTypes.string,
    focusGroupScheduledStartTime: PropTypes.string,
    summaryReportId: PropTypes.string,
    summaryReportStatus: PropTypes.string,
    onReportStatusChanged: PropTypes.func,
    focusGroupActualEndTime: PropTypes.string,
    summaryReportModifiedDate: PropTypes.string,
    warnGeneratedAfterReport: PropTypes.bool
};

export default SessionSummaryReportsListItem;
